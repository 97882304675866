import { css } from 'styled-components'
import { minMq } from '@/shared/styles/breakPoints'
import variables from '@/shared/styles/variables'

const sectionBody = css`
  margin-left: 14px;
  > ul {
    list-style-type: disc;
  }
  > ul > li {
    margin-bottom: 10px;
  }
`

const mainText = css`
  > strong,
  > em {
    color: ${variables.THEME_C};
  }

  > strong {
    font-weight: 700;
  }

  > em {
    font-weight: 500;
  }
`

const topText = css`
  margin-bottom: 20px;

  ${minMq('MD')} {
    margin-bottom: 40px;
  }
`

const myBtn = css`
  margin: 0 auto;
  margin-bottom: 27px;
  width: min(100%, 290px);
`

const flex = css`
  display: block;

  ${minMq('MD')} {
    display: flex;
    justify-content: space-between;
  }
`

const towcolumn = css`
  margin-bottom: 50px;
  width: 100%;

  ${minMq('MD')} {
    padding: 0 24px;
    width: 50%;
  }
`

const border = css`
  border: none;

  ${minMq('MD')} {
    border-right: 1px solid #dddddd;
  }
`

const aboutText = css`
  margin-bottom: 30px;
`

const links = css`
  color: ${variables.BLUE};
  text-decoration: underline;
`

export const style = {
  sectionBody,
  mainText,
  topText,
  myBtn,
  flex,
  towcolumn,
  border,
  aboutText,
  links,
}
