import React from 'react'
import { style } from '@/components/Home/Index.style'
import Page from '@/components/Page/PageTop'
import Content from '@/components/Content/Content'
import SectionTitle from '@/components/SectioinTitle'
import MyBtn from '@/components/MyBtn'

const pageTitle = '北関東・甲信越HIV感染症症例検討会'

const Home = () => {
  return (
    <Page title={pageTitle}>
      <Content>
        <section>
          <p css={style.aboutText}>
            「北関東・甲信越HIV感染症症例検討会」は、北関東甲信越地域（栃⽊県・群⾺県・⻑野県・⼭梨県・新潟県）の中核拠点病院の連携と協⼒のもとエイズ治療拠点病院等の医療従事者を対象に、各地域の事情、地⽅特有の問題点につき情報交換の機会をつくり、北関東甲信越地域のHIV医療の資質向上を⽬的として年に1回開催しています。
          </p>

          <p css={style.aboutText}>
            次回症例検討会に参加を希望される場合は、恐れ⼊りますがご所属とお名前をご記載のうえ下記「問い合わせ」までご連絡ください。
            <br />
            よろしくお願いいたします。
          </p>
          <p>
            関東甲信越ブロック&emsp;エイズ治療ブロック拠点病院
            <br />
            新潟大学医歯学総合病院&emsp;感染管理部
          </p>
        </section>
        <section css={style.flex}>
          <div css={[style.towcolumn, style.border]}>
            <SectionTitle sectionTitle="これまでに開催された症例検討会" />
            <div css={style.sectionBody}>
              <ul>
                <li>
                  <p>第25回（2025年1月31日）オンライン</p>
                  <a css={style.links} href="./common/pdf/gaiyou_25.pdf" target="_blank">
                    開催概要
                  </a>
                </li>
                <li>
                  <p>第24回（2024年1月26日）オンライン</p>
                  <a css={style.links} href="./common/pdf/gaiyou_24.pdf" target="_blank">
                    開催概要
                  </a>
                </li>
                <li>
                  <p>第23回（2023年2月10日）オンライン</p>
                  <a css={style.links} href="./common/pdf/gaiyou_23.pdf" target="_blank">
                    開催概要
                  </a>
                </li>
                <li>
                  <p>第22回（2022年1月28日）オンライン</p>
                  <a css={style.links} href="./common/pdf/gaiyou_22.pdf" target="_blank">
                    開催概要
                  </a>
                </li>
                <li>
                  <p>第21回（2021年1月23日）オンライン</p>
                  <a css={style.links} href="./common/pdf/gaiyou_21.pdf" target="_blank">
                    開催概要
                  </a>
                </li>
                <li>
                  <p>第20回（2020年1月25日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第19回（2019年1月12日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第18回（2018年1月13日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第17回（2017年1月14日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第16回（2016年1月9日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第15回（2015年1月17日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第14回（2014年1月25日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第13回（20013年1月19日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第12回（2012年1月28日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第11回（2011年1月29日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第10回（2010年1月16日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第9回（2009年1月24日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第8回（2008年1月19日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第7回（2007年1月20日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第6回（2006年1月21日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第5回（2005年1月22日）高崎シティギャラリー 群馬県高崎市</p>
                </li>
                <li>
                  <p>第4回（2004年1月24日）高崎ワシントンホテルプラザ 群馬県高崎市</p>
                </li>
                <li>
                  <p>第3回（2003年1月25日）国立高崎病院地域医療研修センター 群馬県高崎市</p>
                </li>
                <li>
                  <p>第2回（2002年1月12日）国立高崎病院地域医療研修センター 群馬県高崎市</p>
                </li>
                <li>
                  <p>第1回（2001年1月19日）新潟大学医学部学士会館 新潟県新潟市</p>
                </li>
              </ul>
            </div>
          </div>
          <div css={style.towcolumn}>
            <SectionTitle
              sectionTitle="第25回症例検討会（2025年1⽉31⽇開催）
          アーカイブ"
            />
            <div css={style.sectionBody}>
              <p css={[style.mainText, style.topText]}>
                当コンテンツはパスワード保護されております。
                <br />
                下記の
                <strong>［保護されたページを表示］</strong>
                ボタンをクリックし、
                <em>ユーザー名</em>と<em>パスワード</em>
                をご入力ください。
              </p>
              <MyBtn href="./main" text="保護されたページを表示" css={style.myBtn} />
              <p css={style.mainText}>
                北関東甲信越エイズ治療拠点病院等の医療従事者を対象とした症例検討会のアーカイブ配信ページです。
                <br />
                ご視聴を希望される場合は、恐れ入りますがご所属とお名前をご記載のうえ下記
                <strong>「問い合わせ」</strong>
                までご連絡ください。
              </p>
              <p>よろしくお願いいたします。</p>
            </div>
          </div>
        </section>
      </Content>
    </Page>
  )
}
export default Home
